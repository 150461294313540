import React from "react"
import { graphql } from "gatsby"
import { linkFragment } from "../link-resolver"
import { Html } from "../components/common/html.component"
import { Slices } from "../components/slices.component"
import { Section } from "../components/common/section.component"
import SEO from "../components/common/seo.component"
import { LandingNavbar } from "../components/landing-navbar.component"
import { LandingFooter } from "../components/landing-footer.component"
import { RequestQuote } from "../components/request-quote.component"
import { RichText } from "../components/common/rich-text.component"
import $ from "jquery"

class landingPage extends React.PureComponent {
  componentDidMount() {
    setTimeout(() => {
      $("a[href=\"#form\"]").on("click touchend", function(e) {
        e.preventDefault()
        document.getElementById("form").scrollIntoView({ block: "center", behavior: "smooth" })
        return false
      })
    }, 2000)
  }

  render() {
    const page = this.props.data.prismic.landingPage
    if (page) {
      return (
        <div className={`landing-page ${page._meta.uid} ${page._meta.lang}`}
             dir={page._meta.lang === "ar-ae" ? "rtl" : "ltr"}>
          <SEO
            title={page.page_title || page.title}
            description={page.description || page.text}
            keywords={page.keywords}
            image={page.preview_image}
            lang={page._meta.lang}
          />
          <LandingNavbar headerSlides={[{}]} lang={page._meta.lang}/>
          <Section className="position-relative"
                   backgroundImage={page.background_image}
                   backgroundColor={page.background_color}
          >
            <div className="slider__wrap"></div>
            <div className="container landing-page-header">
              <div className="text-white">
                <RichText render={page.title}/>
              </div>
            </div>
            <div className="container">
              {page.form_text || page.form_script ? (
                <div className="d-flex flex-wrap flex-md-nowrap flex-column-reverse flex-md-row">
                  <div className="col-lg-6 col-md-12 landing-page-text-wrapper text-white">
                    <RichText render={page.text}/>
                  </div>
                  <div className="col-lg-6 col-md-12 px15">
                    <div className="landing-page-form-wrapper position-relative">
                      <div className="position-relative d-none d-md-block">
                            <RichText render={page.form_text}/>
                      </div>
                      <div class="position-relative d-block d-md-none" data-toggle="collapse"
                           data-target="#landingForm"
                           aria-expanded="false" aria-controls="landingForm">
                        <RichText render={page.form_text}/>
                        <b className="glyphicon glyphicon-chevron-down" />
                      </div>
                      <div className="collapse" id="landingForm">
                        <Html html={page.form_script}/>
                      </div>
                    </div>
                  </div>
                </div>
              ) : (
                <div className="landing-page-text-wrapper text-white">
                  <RichText render={page.text}/>
                </div>
              )}

            </div>
          </Section>
          <main className="main_alt">
            <Slices body={page.body} large={page._meta.lang === "en-us"}/>
          </main>
          <LandingFooter lang={page._meta.lang}/>
          <RequestQuote lang={"en-us"}/>
        </div>
      )
    }
    return null
  }
}

landingPage.fragments = [linkFragment]
export default landingPage

export const query = graphql`
    query landingPageQuery($uid: String!, $lang: String!) {
        prismic {
            landingPage(uid: $uid, lang: $lang) {
                page_title
                description
                keywords {
                    keyword
                }
                preview_image
                _meta {
                    uid
                    lang
                }
                title
                text
                form_text
                form_script
                background_image
                background_color
                body {
                    ...on PRISMIC_LandingPageBodyShipping_calculator {
                        type
                        label
                        primary {
                            bg_color
                            bg_image
                            title
                            text
                            calculator_title
                            calculate_button_calculate
                            calculator_button_request
                            calculator_contact_email
                            calculator_contact_first_name
                            calculator_contact_last_name
                            calculator_contact_telephone
                            calculator_contact_title
                            calculator_destination_container_1
                            calculator_destination_container_2
                            calculator_destination_country
                            calculator_destination_port
                            calculator_destination_title
                            calculator_location_select
                            calculator_location_title
                            calculator_title
                            calculator_total_cost
                            calculator_tub_itinerary
                            calculator_tub_rate_quote
                        }
                    }
                    ...on PRISMIC_LandingPageBodyVideo {
                        type
                        label
                        primary {
                            bg_color
                            bg_image
                            title
                            text
                        }
                        fields {
                            video_thumbnail
                            video_url
                        }
                    }
                    ... on PRISMIC_LandingPageBodyTestimonials {
                        type
                        label
                        primary {
                            text
                            title
                            bg_color
                            bg_image
                        }
                        fields {
                            client_name
                            client_photo
                            image
                            link {
                                ...link
                            }
                            link_text
                            link_text
                            rating
                            text
                            video_url
                        }
                    }
                    ... on PRISMIC_LandingPageBodyPricing_plans {
                        type
                        label
                        fields {
                            price_units
                            plan_price
                            plan_name
                            plan_features
                            link_text
                            link_style
                            link {
                                ...link
                            }
                            is_free_plan
                        }
                        primary {
                            bg_image
                            bg_color
                            title
                            text
                        }
                    }
                    ... on PRISMIC_LandingPageBodyFeatute {
                        type
                        label
                        primary {
                            bg_image
                            bg_color
                            text
                            title
                        }
                        fields {
                            image
                            link_style
                            link_text
                            text
                            title
                            link {
                                ...link
                            }
                        }
                    }
                    ... on PRISMIC_LandingPageBodyBlock_with_text_and_image {
                        type
                        label
                        primary {
                            bg_color
                            bg_image
                            min_height
                            image
                            link_style
                            link_text
                            text
                            title
                            link {
                                ...link
                            }
                        }
                    }
                    ... on PRISMIC_LandingPageBodyRich_text {
                        type
                        label
                        primary {
                            text
                        }
                    }
                    ... on PRISMIC_LandingPageBodyText_with_title {
                        type
                        label
                        fields {
                            text
                        }
                        primary {
                            background_color
                            background_image
                            title
                            link_text
                            text
                            link {
                                ... on PRISMIC_ContentPage {
                                    _linkType
                                    _meta {
                                        uid
                                        type
                                        lang
                                    }
                                }
                                ... on PRISMIC_BlogPage {
                                    _linkType
                                    _meta {
                                        uid
                                        type
                                        lang
                                    }
                                }
                                ... on PRISMIC_BlogCategory {
                                    _linkType
                                    _meta {
                                        uid
                                        type
                                        lang
                                    }
                                }
                                ... on PRISMIC_BlogMainPage {
                                    _linkType
                                    _meta {
                                        uid
                                        type
                                        lang
                                    }
                                }
                                ... on PRISMIC_LandingPage {
                                    _linkType
                                    _meta {
                                        uid
                                        type
                                        lang
                                    }
                                }
                            }
                        }
                    }
                    ... on PRISMIC_LandingPageBodyImage_with_text {
                        type
                        label
                        primary {
                            background_color
                            background_image
                            text
                        }
                        fields {
                            image
                            text
                        }
                    }
                    ... on PRISMIC_LandingPageBodyTwo_columns {
                        type
                        label
                        fields {
                            border_color
                            html
                            text
                        }
                        primary {
                            background_color
                            background_image
                            text
                        }
                    }
                    ... on PRISMIC_LandingPageBodyMap {
                        type
                        label
                        primary {
                            latitude
                            longitude
                        }
                    }
                    ... on PRISMIC_LandingPageBodyForm {
                        type
                        label
                        primary {
                            bg_color
                            bg_image
                            form_script
                            form_url
                            text
                        }
                    }
                    ... on PRISMIC_LandingPageBodyGallery {
                        type
                        label
                        fields {
                            image
                        }
                        primary {
                            bg_color
                            bg_image
                            text
                        }
                    }
                    ... on PRISMIC_LandingPageBodyText_with_title_list {
                        type
                        label
                        fields {
                            text
                            title
                        }
                        primary {
                            text
                        }
                    }
                    ... on PRISMIC_LandingPageBodyContacts {
                        type
                        label
                        primary {
                            bg_color
                            bg_image
                            title
                            text
                        }
                        fields {
                            address
                            description
                            email
                            fax
                            phone
                        }
                    }
                }
            }
        }
    }
`
