import React from "react"
import { graphql, StaticQuery } from "gatsby"
import $ from "jquery"
import { withPreview } from "@prismicio/gatsby-source-prismic-graphql"
import { Slices } from "./slices.component"
import { linkFragment } from "../link-resolver"
import { PlainStructuredText } from "./common/plain-structured-text.component"
import { Image } from "./common/image.component"
import { convertRichTextToPlain } from "../utils/text"
import { RichText } from "./common/rich-text.component"

const query = graphql`
    query landingFooterQuery {
        prismic {
            allFooters(uid: "landing-footer") {
                edges {
                    node {
                        _meta {
                            lang
                        }
                        body {
                            ... on PRISMIC_FooterBodyCustom_script {
                                type
                                label
                                fields {
                                    script_html
                                    script_url
                                }
                            }
                            ... on PRISMIC_FooterBodyMenu {
                                type
                                label
                                primary {
                                    nav_text
                                    nav_link {
                                        ...link
                                    }
                                }
                                fields {
                                    icon
                                    link {
                                        ...link
                                    }
                                    link_style
                                    link_text
                                }
                            }
                        }
                        phone
                        partners_text
                        partners {
                            image
                        }
                        fax
                        email
                        copyright
                    }
                }
            }
        }
    }

`

export class LandingFooter extends React.Component {
  componentDidMount() {
    const carousel = $(".partners")
    carousel.owlCarousel({
      items: 5,
      itemsTablet: 3,
      itemsMobile: 2,
    })
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const carousel = $(".partners")
    carousel.owlCarousel({
      items: 5,
      itemsTablet: 3,
      itemsMobile: 2,
    })
  }

  componentWillMount() {
    console.log("component will mount")
  }

  render() {
    return (
      <StaticQuery query={
        query
      } render={withPreview(data => {
          const lang = this.props.lang || "en-us"
          const edge = data.prismic.allFooters.edges
            .find(item => item.node._meta.lang === lang)
          if (edge) {
            const { copyright, email, fax, phone, body, partners, partners_text } = edge.node

            return (
              <footer className="landing-footer">
                <div className="container">
                  {partners && partners.length ? (
                    <div style={{marginBottom: "45px"}}>
                      <br/>
                      <div className="row">
                        {convertRichTextToPlain(partners_text) ? (<div className="col-sm-3">
                            <h2 className="visible-lg">
                              <PlainStructuredText structuredText={partners_text}/>
                            </h2>
                            <h3 className="visible-md visible-xs" style={{ color: "#fff" }}>
                              <PlainStructuredText structuredText={partners_text}/>
                            </h3>
                            <br className="visible-sm"/>
                            <h4 className="visible-sm" style={{ color: "#fff" }}>
                              <PlainStructuredText structuredText={partners_text}/>
                            </h4>
                          </div>
                        ) : null
                        }
                        <div className={convertRichTextToPlain(partners_text) ? "col-sm-9" : "col-sm-12"}>
                          <div className="partners">
                            {
                              partners.map(partner => (
                                <div className="item">
                                  <Image className="img-responsive partner center-block" image={partner.image}/>
                                </div>
                              ))
                            }
                          </div>
                        </div>
                      </div>
                      <hr/>
                    </div>
                  ) : null}
                  <section>
                    <div className="row">
                      <div className="col-sm-12">
                        <Slices body={body}/>
                      </div>
                    </div>
                    <div className="row d-flex flex-wrap">
                      <div className="landing-footer-col-1 col-sm-5">
                        <div>
                          Copyright {new Date().getFullYear()} All right reserved
                        </div>
                        <div>{copyright}</div>
                      </div>
                      <div className="landing-footer-col-2 px15 ml-md-auto col-sm-offset-2">
                        <div><RichText className="display-ib landing-footer-phone" render={phone} /> </div>
                        {fax ? (<div>Fax: {fax}</div>) : null}
                        <div>{email}</div>
                      </div>
                    </div>
                  </section>
                </div>
              </footer>
            )
          } else return null
        }
        , query, [linkFragment])
      }/>
    )
  }
}

