import React from "react"
import { graphql, Link, StaticQuery } from "gatsby"
import $ from "jquery"
import PropTypes from "prop-types"
import { Image } from "./common/image.component"
import { withPreview } from "@prismicio/gatsby-source-prismic-graphql"
import { Slices } from "./slices.component"
import { linkFragment } from "../link-resolver"

const query = graphql`
    query laningNavbarQuery {
        prismic {
            allNavbars(uid: "landing-header") {
                edges {
                    node {
                        logo
                        _meta {
                            lang
                        }
                        buttons {
                            icon
                            link {
                                ...link
                            }
                            link_text
                            style
                        }
                        body {
                            ... on PRISMIC_NavbarBodyMenu {
                                type
                                label
                                primary {
                                    nav_text
                                    nav_link {
                                        ...link
                                    }
                                }
                                fields {
                                    icon
                                    link {
                                        ...link
                                    }
                                    link_style
                                    link_text
                                }
                            }
                            ... on PRISMIC_NavbarBodyCustom_script {
                                type
                                label
                                fields {
                                    script_html
                                    script_url
                                }
                            }
                        }
                    }
                }
            }
        }
    }

`

export class LandingNavbar extends React.Component {
  componentDidMount() {
    $("#quote").on("show.bs.modal", function() {
      $("#requestQuoteAlert").hide()
    })

    $("#requestQuote").ajaxForm(function(responseText, statusText, xhr, $form) {
      if (statusText === "success") {
        $("#requestQuoteAlert").removeClass("alert-danger").addClass("alert-success").text("Quote was sent successfully.").show()
        $("#requestQuote").resetForm()
        setTimeout(function() {
          $("#quote").modal("hide")
        }, 4000)
      } else {
        $("#requestQuoteAlert").removeClass("alert-success").addClass("alert-danger").text(responseText.Message).show()
      }
      document.getElementById("formsubmit").disabled = false
    })

    const path = $(".top-nav .nav-pills li a")

    path.each(function() {
      if (window.location.pathname === $(this)[0].pathname) {
        $(this).parent().siblings().removeClass("active")
        $(this).parent().addClass("active")
      }
    })
  }

  render() {
    const { slides, lang } = this.props
    const noBG = (slides.length === 1
      && (!slides[0].background_image))
    return <StaticQuery
      query={query}
      render={withPreview(
        data => {
          const item = data.prismic.allNavbars.edges
            .find(item => item.node._meta.lang === lang)
          if (item) {
            const { logo, buttons } = item.node
            return (
              <div className="top-navbar">
                <div className="top-nav_mob visible-xs">
                  <div className="container">
                    <div className="row">
                      <div className="col-xs-2">
                        <a href="/" className="nav-image-wrapper"><Image image={logo}/></a>
                      </div>
                      <div className="col-xs-8 mt10">
                        <a style={{ color: "#fff", fontSize: "11px" }} data-toggle="modal" href='#quote'>Request a
                          Quote</a>
                      </div>
                    </div>
                  </div>
                </div>
                <div className={`${noBG ? "top-nav_alt" : "top-nav"} hidden-xs`}>
                  <div className="container">
                    <div className="row">
                      <div className="col-xs-3 col-sm-2">
                        <div className="logo"><a href="/" style={{ width: "78px" }} className="center-block"><Image
                          image={logo} className="navbar-logo"/></a></div>
                      </div>
                      <div className="col-xs-9 col-sm-10">
                        <br/>
                        <div className="row hidden-xs">
                          <div className="col-sm-12 text-right header-btn">
                            {buttons.map(btn => {
                              if(!btn.link) {
                                return null;
                              }
                              return (
                                <a data-toggle="modal"
                                   href={btn.link.url && btn.link.url.indexOf("#") > -1 ? "#" + btn.link.url.split("#")[1] : btn.link.url}
                                   className={`ml15 btn btn-${btn.style}`}>
                                  {btn.icon ?
                                    <Image className="display-ib mr15" style={{ width: "15px", height: "15px" }}
                                           image={btn.icon}/> : null}
                                  {btn.link_text}
                                </a>
                              )
                            })}
                          </div>
                        </div>
                        <div className="row visible-xs">
                          <div className="col-sm-12 text-right header-btn">
                            {buttons.map(btn => {
                              if(!btn.link) {
                                return null;
                              }
                              return (
                                <a data-toggle="modal"
                                   href={btn.link.url && btn.link.url.indexOf("#") > -1 ? "#" + btn.link.url.split("#")[1] : btn.link.url}
                                   className={`ml15 btn btn-${btn.style}`}>
                                  {btn.icon ?
                                    <Image className="display-ib mr15" style={{ width: "15px", height: "15px" }}
                                           image={btn.icon}/> : null}
                                  {btn.link_text}
                                </a>
                              )
                            })}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <Slices body={item.node.body}/>
              </div>
            )
          }
          return null
        }, query, [linkFragment])}
    />
  }
}

LandingNavbar.propTypes = {
  lang: PropTypes.string,
  slides: PropTypes.array,
}

LandingNavbar.defaultProps = {
  lang: "en-us",
  slides: [],
}

